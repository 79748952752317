






































































import {Component, Prop, Vue} from 'vue-property-decorator';
import vSelect from 'vue-select';
import {OrderService} from "@/views/order/OrderService";
import {Status} from "@/constants/Order";
import _filter from "lodash/filter";

@Component({
    components: {
        vSelect
    }
})
export default class OrderGoodsIssuedUpdateStatus extends Vue {
    @Prop({default: 0}) selectedRows!: Array<any>;

    private orderService: OrderService = new OrderService();

    popupActive: boolean = false;
    showResult: boolean = false;
    showOrdersSuccessResult: boolean = false;
    updatedRows: Array<any> = [];
    status: Status | '' = '';
    listStatus: Array<any> = [
        {value: 3, text: 'Đang giao'},
        {value: 4, text: 'Đã giao'}
    ];

    ordersSuccess: any = [];

    constructor() {
        super();
    }

    open() {
        this.popupActive = true;
    }

    async onSave() {
        if (!this.status) {
            this.$vs.notify({
                title: '',
                text: "Vui lòng chọn chọn trạng thái!",
                color: "danger"
            });

            return;
        }

        let orderIds = this.updatedRows.map(m => m.id);
        this.$vs.loading();
        let result = await this.orderService.updateStatusGoodsIssued(orderIds, this.status);
        this.$vs.loading.close();

        if (!result || !result.code)
            return;

        this.$emit('onUpdateStatus', orderIds, this.status);

        // this.$vs.notify({
        //     title: '',
        //     text: "Cập nhật trạng thái đơn hàng thành công!",
        //     color: "success"
        // });

        this.ordersSuccess = result.data;
        this.showResult = true;

        // this.popupActive = false;
    }

    get titleText() {
        this.updatedRows = _filter(this.selectedRows, (item) => {
            return item.status === Status.GoodsIssued;
        });

        return `Có ${this.updatedRows.length}/${this.selectedRows.length} đơn ở trạng thái Đã xuất kho. Bạn có muốn đổi trạng thái không?`;
    }

    get updateStatusDetailText() {
        return `Đã chuyển trạng thái ${this.status === Status.Delivering ? 'Đang giao' : 'Đã giao'}`
    }

    onShowOrdersResult(type: string) {
        if (type === 'success')
            this.showOrdersSuccessResult = !this.showOrdersSuccessResult;
    }
}

