














































import {Component, Vue} from 'vue-property-decorator';
import {OrderService} from '@/views/order/OrderService';
// import {Status} from "@/constants/Order";
// import OrderStatus from "@/views/customer/insight/OrderStatus.vue";
import {TabState} from "@/store/order/order-state";
import { FormType } from "@/constants/Order";
import { OptionOrderStatus } from '@/models/order/OrderSearchResult';

@Component({})
export default class CellRendererStatusChange extends Vue {
    private orderService: OrderService;
    params: any = null;

    constructor() {
        super();
        this.orderService = new OrderService();
    }
    get chipColor() {
        return (value: any) => {
            return new OptionOrderStatus(value).color;
        }
    }

    get isStatus()
    {
        return this.params.data.status != null;
    }

    get statusName() {
        return (value: any) => {
            return new OptionOrderStatus(value).name;
        }
    }

    async changeStatus(status: any) {
        let orderCode = this.params.data.code;

        try {
            const result = await this.orderService.updateStatus(this.params.data.id, status, '');

            if (result.code > 0) {
                this.$vs.notify({
                    title: '',
                    text: "Cập nhật trạng thái thành công!",
                    color: "success"
                });

                let gridApi = this.params.node.gridApi;

                if (gridApi) {
                    // let rows = gridApi.getSelectedRows();
                    //
                    // if (rows && rows.length > 0) {
                    //     gridApi.updateRowData({remove: rows});
                    // }
                    this.params.data.status = status;
                    gridApi.refreshCells();
                }
            }
        } catch (error) {
            this.$vs.notify({
                title: '',
                text: error.message,
                color: "danger"
            });

            return;
        }
    }

    // async deleteRecord() {
    //     try {
    //         const result = await this.orderService.delete(this.params.data.id);
    //
    //         if (result.code > 0) {
    //             this.$vs.notify({
    //                 color: 'success',
    //                 title: '',
    //                 text: 'Xóa đơn hàng thành công'
    //             })
    //
    //             let gridApi = this.params.node.gridApi;
    //
    //             if (gridApi) {
    //                 let rows = gridApi.getSelectedRows();
    //
    //                 if (rows && rows.length > 0) {
    //                     gridApi.updateRowData({remove: rows});
    //                 }
    //
    //                 gridApi.refreshCells();
    //             }
    //         }
    //     } catch (error) {
    //         this.$vs.notify({
    //             title: '',
    //             text: error.message,
    //             color: "danger"
    //         });
    //
    //         return;
    //     }
    // }

    orderReturn(){
        let orderId = this.params.data.id;

        const tab: TabState = {
            id: orderId,
            type: FormType.Return,
        };
        
        this.$store.dispatch('order/addTab', tab);
        this.$router.push({ name: 'orderAdd'});
    }

    get isEdit() {
        let status = this.params.data.status;

        if(!this.params.data.isLogisticManager)
        {
            return false;
        }

        return status == 2 || status == 3 || status == 4 || status == 5 || status == 6 || status == 7;
    }

    get orderId() {
        return this.params.data.id;
    }
}
